import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './assets/tailwind.css'

// Icon
import WechatIcon from './components/Icon/WechatIcon.vue'
import ReelIcon from './components/Icon/ReelIcon.vue'

Vue.config.productionTip = false
// 申明忽略标签 - 用于跳转小程序
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

// Icon components
Vue.component('WechatIcon', WechatIcon)
Vue.component('ReelIcon', ReelIcon)



new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
