<template>
  <div class="fixed inset-0">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 gap-4 mt-8">
        <div v-for="item in items" :key="item.title" class="space-y-2">
          <template v-if="item.route">
            <router-link
              :to="item.route"
              class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
            >
              <div class="p-4">
                <h2 class="font-bold">
                  {{ $t(item.title) }}
                </h2>
              </div>
            </router-link>
          </template>

          <template v-else>
            <a
              :href="item.url"
              class="bg-black rounded-md text-white font-bold w-full mx-auto py-2 flex items-center justify-center"
            >
              <div class="p-4">
                <h2 class="font-bold">
                  {{ $t(item.title) }}
                </h2>
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      title: "荔枝碗船廠 AR 列表",
      items: [
        {
          title: "scene1_title",
          route: "/scene-1",
        },
        {
          title: "scene2_title",
          route: "/scene-2",
        },
        {
          title: "scene3_title",
          route: "/scene-3",
        },
        {
          title: "scene4_title",
          route: "/scene-4",
        },
      ],
    };
  },
  created() {
    this.$route.meta.title = this.title;
    document.title = this.$route.meta.title;
  },
};
</script>
