import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import Collection from '../views/scene-list.vue'
import Scene1 from '../views/scene-1.vue'
import Scene2 from '../views/scene-2.vue'
import Scene2AR from '../views/scene-2-ar.vue'
import Scene3 from '../views/scene-3.vue'
import Scene4 from '../views/scene-4.vue'
import Scene4AR from '../views/scene-4-ar.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/collection',
    name: 'collection',
    component: Collection
  },
  {
    path: '/scene-1',
    name: 'scene-1',
    component: Scene1 
  },
   {
    path: '/scene-2',
    name: 'scene-2',
    component: Scene2
  },
  {
    path: '/scene-2-ar',
    name: 'scene-2-ar',
    component: Scene2AR
  },
   {
    path: '/scene-3',
    name: 'scene-3',
    component: Scene3 
  },
   {
    path: '/scene-4',
    name: 'scene-4',
    component: Scene4 
  },
  {
    path: '/scene-4-ar',
    name: 'scene-4-ar',
    component: Scene4AR
  }
]

const router = new VueRouter({
  routes
})

export default router
