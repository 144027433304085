<template>
  <div id="app">
    <iframe
      ref="kivicubeScene"
      v-bind="props"
      @load="load"
      @error="error"
      @ready="ready"
      @downloadAssetStart="downloadAssetStart"
      @downloadAssetProgress="downloadAssetProgress"
      @downloadAssetEnd="downloadAssetEnd"
      @loadSceneStart="loadSceneStart"
      @loadSceneEnd="loadSceneEnd"
      @sceneStart="sceneStart"
      @tracked="tracked"
      @lostTrack="lostTrack"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "船廠 | AR Scene",
      name: "船廠 AR",
      props: {},
      view: null,
      previewImage: "/static/images/preview/scene-4.png",
      sceneId: "4F5SpegsA3898gxpyj84b4NC38msFd7D",
      license: "",
      version: "tracking2",
      loading: false,
      progress: 0,
      scanning: false,
      photoing: false,
      showScene: false,
      preview: false,
      model: null,
      audio: null,
    };
  },
  async mounted() {
    // 变量kivicubeIframePlugin来自于kivicube lib：https://www.kivicube.com/lib/iframe-plugin.js
    // index.html之中使用script标签引入。
    // eslint-disable-next-line no-undef
    this.props = kivicubeIframePlugin.openKivicubeScene(
      this.$refs.kivicubeScene,
      {
        sceneId: this.sceneId,
        // hideLogo: true,
        // hideTitle: true,
        // hideDownload: true,
        // hideLoading: true,
        hideScan: true,
        // hideTakePhoto: true,
        // hideBackground: true,
      },
      false
    );
  },
  beforeDestroy() {
    window.kivicubeIframePlugin.destroyKivicubeScene(this.$refs.kivicubeScene);
  },
  created() {
    this.$route.meta.title = this.title;
    document.title = this.$route.meta.title;

    window.location.href = "https://www.kivicube.com/scenes/" + this.sceneId;
  },
  methods: {
    load() {
      console.log("load");
    },

    error() {
      console.log("error");
    },

    ready({ detail: view }) {
      console.log("ready", view);
      this.view = view;
      this.loading = true;
    },

    downloadAssetStart() {
      console.log("downloadAssetStart");
    },

    downloadAssetProgress({ detail }) {
      console.log("downloadAssetProgress", detail);
      this.progress = detail * 100;
    },

    downloadAssetEnd() {
      console.log("downloadAssetEnd");
    },

    loadSceneStart() {
      console.log("loadSceneStart");
    },

    loadSceneEnd() {
      console.log("loadSceneEnd");
    },

    sceneStart() {
      console.log("sceneStart");

      // this.scanning = true;
      this.loading = false;
      this.photoing = true;

      console.log("locale", this.$i18n.locale);

      if (typeof this.view.getObject === "function") {
        // this.model = this.view.getObject("model");
        // if (this.$i18n.locale === "tw") {
        //   this.audio = this.view.getObject("audio_can");
        // } else if (this.$i18n.locale === "cn") {
        //   this.audio = this.view.getObject("audio_man");
        // } else {
        //   this.audio = this.view.getObject("audio_eng");
        // }
      }
    },

    tracked() {
      this.scanning = false;
      this.photoing = true;

      // this.playModel(this.model, "Animation", false);
      // this.playAudio(this.audio);
    },

    lostTrack() {
      this.scanning = true;
      this.photoing = false;
    },

    playAudio(audio) {
      audio.loop = false; // 是否循环播放
      audio.play();
    },

    pauseAudio(audio) {
      audio.pause();
    },

    stopAudio(audio) {
      audio.stop();
    },

    playbackAudio(audio) {
      audio.loop = false; // 是否循环播放
      audio.playback();
    },

    // Control Model
    playModel(model, name, loop) {
      if (!model) return false;
      this.stopModel(model);
      const names = model.getAnimationNames();
      if (!Array.isArray(names)) return;
      if (!names.includes(name)) return false;
      model.playAnimation({
        name, // 动画名称
        loop, // 是否循环播放
        clampWhenFinished: true, // 播放完毕后是否停留在动画最后一帧
      });
    },

    stopModel(model) {
      if (!model) return;
      const names = model.getAnimationNames();
      if (!Array.isArray(names)) return;
      names.forEach((name) => {
        model.pauseAnimation(name);
      });
    },

    // 拍照相片
    // takePhoto: async function () {
    //   wx.showLoading({ title: "拍照中...", mask: true });

    //   try {
    //     // this.stop();
    //     const imagePath = await this.view.takePhoto();

    //     this.setData({
    //       previewImage: imagePath,
    //       preview: true
    //     })

    //     wx.hideLoading()
    //     console.log(this.data.previewImage)
    //     return;
    //   } catch (e) {
    //     wx.hideLoading();
    //     console.error(e);
    //     errorHandler(`拍照失敗 - ${e.message}`);
    //   }
    // },

    // 儲存相片
    // async savePhoto(){
    //   const userAuth = await setAuth(
    //       "scope.writePhotosAlbum",
    //       "相册權限被拒絕",
    //       "保存照片需要您授予相册權限"
    //     );
    //   if (!userAuth) {
    //   return wx.showToast({ title: "保存照片失败, 需要相機權限", icon: none });
    //   }
    //   wx.saveImageToPhotosAlbum({
    //       filePath: this.data.previewImage,
    //       success() {
    //       wx.showToast({ title: "保存照片成功", icon: "success" });
    //       },
    //       fail(e) {
    //       console.error(e);
    //       wx.showToast({ title: "保存照片失敗", icon: "error" });
    //       }
    //   })
    // },

    // 關閉相片
    closePhoto() {
      this.previewImage = "";
      this.preview = false;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
