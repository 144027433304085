<template>
  <div
    class="flex items-center justify-center size-full bg-gray-900"
    style="width: 100vw; height: 100vh"
  >
    <div>
      <div class="icon w-24 h-24 mx-auto">
        <ReelIcon />
      </div>

      <h2 class="text-3xl text-white font-bold text-center pt-8 pb-4">
        {{ name }}
      </h2>

      <p class="text-gray-300 px-8 leading-relaxed">
        建議使用微信小程序，感受更佳的AR體驗
      </p>
      <p class="text-gray-300 px-8 leading-relaxed text-sm">
        It is recommended to use the WeChat Mini Program for a better AR
        experience.
      </p>

      <!-- Wechat Button -->
      <button
        class="bg-green-600 text-white font-bold text-lg w-2/3 py-4 px-12 rounded-full mx-auto mt-8 mb-4 flex items-center justify-center"
        @click="goToWeChat"
      >
        <div class="mr-2 w-8">
          <WechatIcon />
        </div>

        <span>前往微信</span>
      </button>

      <!-- Button -->
      <router-link
        to="/scene-2-ar"
        class="border text-white font-bold text-lg py-4 px-12 w-2/3 rounded-full mx-auto mb-4 flex items-center justify-center"
      >
        繼續體驗
      </router-link>
    </div>

    <!-- <iframe
      ref="kivicubeScene"
      v-bind="props"
      @load="load"
      @error="error"
      @ready="ready"
      @downloadAssetStart="downloadAssetStart"
      @downloadAssetProgress="downloadAssetProgress"
      @downloadAssetEnd="downloadAssetEnd"
      @loadSceneStart="loadSceneStart"
      @loadSceneEnd="loadSceneEnd"
      @sceneStart="sceneStart"
      @tracked="tracked"
      @lostTrack="lostTrack"
    /> -->
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      title: "𠝹木機 | AR Scene",
      name: "𠝹木機",
      props: {},
      view: null,
      previewImage: "/static/images/preview/scene-2.png",
      sceneId: "rS36685ontMXh8Eqk5Ksb3dY66jB9MfD",
      wechatAppID: "wx579b8778aa0d404d",
      wechatPath: "pages/ar/scene2/index",
      license: "",
      version: "tracking2",
      loading: false,
      progress: 0,
      scanning: false,
      photoing: false,
      showScene: false,
      preview: false,
      model: null,
      audio: null,
    };
  },
  async mounted() {
    // 变量kivicubeIframePlugin来自于kivicube lib：https://www.kivicube.com/lib/iframe-plugin.js
    // index.html之中使用script标签引入。
    // eslint-disable-next-line no-undef
    // this.props = kivicubeIframePlugin.openKivicubeScene(
    //   this.$refs.kivicubeScene,
    //   {
    //     sceneId: this.sceneId,
    //     // hideLogo: true,
    //     // hideTitle: true,
    //     // hideDownload: true,
    //     // hideLoading: true,
    //     hideScan: true,
    //     // hideTakePhoto: true,
    //     // hideBackground: true,
    //   },
    //   false
    // );
    // this.getConfig();
  },
  beforeDestroy() {
    window.kivicubeIframePlugin.destroyKivicubeScene(this.$refs.kivicubeScene);
  },
  created() {
    this.$route.meta.title = this.title;
    document.title = this.$route.meta.title;
  },
  methods: {
    goToWeChat() {
      // window.location.href =
      //   "https://www.kivicube.com/scenes/J23IJpr2Gty8fFnlJn97sg800hX0LY5F";

      window.location.href = `weixin://dl/business/?appid=${this.wechatAppID}&path=${this.wechatPath}&env_version=release`;

      // wx.miniProgram.navigateTo({
      //   appId: this.wechatAppID, //要打开的小程序 appId
      //   url: this.wechatPath, //指定跳转至小程序页面的路径
      //   success: function () {
      //     console.log("success"); //页面跳转成功的回调函数
      //   },
      // });
    },

    getWxEnv() {
      let ua = navigator.userAgent.toLowerCase();
      let that = this;
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            that.isWeChat = 1; //1微信小程序 2微信浏览器 3不在微信
          } else {
            that.isWeChat = 2;
          }
        });
      } else {
        that.isWeChat = 3;
      }
    },

    getConfig() {
      wx.config({
        debug: true,
        appId: this.wechatAppID,
        timestamp: "0000",
        nonceStr: "noncestr",
        signature: "signature",
        jsApiList: [],
        openTagList: ["wx-open-launch-weapp"],
      });
      wx.ready((res) => {
        console.log("wx ready", res);
      });
      wx.error((res) => {
        console.log("wx error", res);
      });
    },

    load() {
      console.log("load");
    },

    error() {
      console.log("error");
    },

    ready({ detail: view }) {
      console.log("ready", view);
      this.view = view;
      this.loading = true;
    },

    downloadAssetStart() {
      console.log("downloadAssetStart");
    },

    downloadAssetProgress({ detail }) {
      console.log("downloadAssetProgress", detail);
      this.progress = detail * 100;
    },

    downloadAssetEnd() {
      console.log("downloadAssetEnd");
    },

    loadSceneStart() {
      console.log("loadSceneStart");
    },

    loadSceneEnd() {
      console.log("loadSceneEnd");
    },

    sceneStart() {
      console.log("sceneStart");

      this.scanning = true;
      this.loading = false;

      console.log("locale", this.$i18n.locale);

      if (typeof this.view.getObject === "function") {
        this.model = this.view.getObject("model");

        // if (this.$i18n.locale === "tw") {
        //   this.audio = this.view.getObject("audio_can");
        // } else if (this.$i18n.locale === "cn") {
        //   this.audio = this.view.getObject("audio_man");
        // } else {
        //   this.audio = this.view.getObject("audio_eng");
        // }
      }
    },

    tracked() {
      this.scanning = false;
      this.photoing = true;

      // this.playModel(this.model, "Animation", false);
      // this.playAudio(this.audio);
    },

    lostTrack() {
      this.scanning = true;
      this.photoing = false;
    },

    playAudio(audio) {
      audio.loop = false; // 是否循环播放
      audio.play();
    },

    pauseAudio(audio) {
      audio.pause();
    },

    stopAudio(audio) {
      audio.stop();
    },

    playbackAudio(audio) {
      audio.loop = false; // 是否循环播放
      audio.playback();
    },

    // Control Model
    playModel(model, name, loop) {
      if (!model) return false;
      this.stopModel(model);
      const names = model.getAnimationNames();
      if (!Array.isArray(names)) return;
      if (!names.includes(name)) return false;
      model.playAnimation({
        name, // 动画名称
        loop, // 是否循环播放
        clampWhenFinished: true, // 播放完毕后是否停留在动画最后一帧
      });
    },

    stopModel(model) {
      if (!model) return;
      const names = model.getAnimationNames();
      if (!Array.isArray(names)) return;
      names.forEach((name) => {
        model.pauseAnimation(name);
      });
    },

    // 拍照相片
    // takePhoto: async function () {
    //   wx.showLoading({ title: "拍照中...", mask: true });

    //   try {
    //     // this.stop();
    //     const imagePath = await this.view.takePhoto();

    //     this.setData({
    //       previewImage: imagePath,
    //       preview: true
    //     })

    //     wx.hideLoading()
    //     console.log(this.data.previewImage)
    //     return;
    //   } catch (e) {
    //     wx.hideLoading();
    //     console.error(e);
    //     errorHandler(`拍照失敗 - ${e.message}`);
    //   }
    // },

    // 儲存相片
    // async savePhoto(){
    //   const userAuth = await setAuth(
    //       "scope.writePhotosAlbum",
    //       "相册權限被拒絕",
    //       "保存照片需要您授予相册權限"
    //     );
    //   if (!userAuth) {
    //   return wx.showToast({ title: "保存照片失败, 需要相機權限", icon: none });
    //   }
    //   wx.saveImageToPhotosAlbum({
    //       filePath: this.data.previewImage,
    //       success() {
    //       wx.showToast({ title: "保存照片成功", icon: "success" });
    //       },
    //       fail(e) {
    //       console.error(e);
    //       wx.showToast({ title: "保存照片失敗", icon: "error" });
    //       }
    //   })
    // },

    // 關閉相片
    closePhoto() {
      this.previewImage = "";
      this.preview = false;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.open-launch-weapp-btn {
  width: 234px;
  border: none;
  background: #ff4c5a;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 24px;
  margin: 20px auto;
  padding: 12px 0;
  margin-left: 50%;
  transform: translateX(-117px);
  text-align: center;
}
</style>
