import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
  en: {
    scene1_title: 'Fishing Sailboat',
    scene2_title: 'Wood Cutting Machine',
    scene3_title: 'Mechanised Fishing Boats',
    scene4_title: 'Shipyard',
    loading_wait: 'Loading， Please wait a monent...',
    please_aim: "Please Aim to target image",
    save_button_text: "Save Photo"
  },
  tw: {
    scene1_title: '風力帆船',
    scene2_title: '𠝹木機',
    scene3_title: '機動漁船',
    scene4_title: '船廠',
    loading_wait: '加載中，請稍後...',
    please_aim: "請對準識別圖",
    save_button_text: "保存相片"
  },
  cn: {
    scene1_title: '风力帆船',
    scene2_title: '𠝹木机',
    scene3_title: '机动渔船',
    scene4_title: '船厂',
    loading_wait: '加載中，請稍後...',
    please_aim: "請對準識別圖",
    save_button_text: "保存相片"
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'tw', // set locale
  messages, // set locale messages
})

export default i18n

// function loadLocaleMessages () {
//   const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
